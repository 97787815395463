<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <router-link
        v-if="selectedClient && selectedCampaign"
        class="client-profil-wrap"
        :to="{
          name: 'Campaign',
          params: {
            clientId: selectedClient.id,
            campaignId: selectedCampaign.id,
          },
        }"
      >
        <div class="overlay-back">
          <svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.768 12c.317 0 .621-.126.864-.366.475-.492.475-1.301 0-1.793L2.945 6l3.7-3.839c.474-.492.474-1.3 0-1.793a1.195 1.195 0 0 0-1.729 0L.353 5.105C.122 5.345 0 5.66 0 6c0 .341.134.657.353.897l4.551 4.736c.244.24.56.366.864.366z"
              fill="#fff"
            />
          </svg>
        </div>
        <client-logo v-if="selectedClient" :client="selectedClient" class-name="is-medium"></client-logo>
      </router-link>

      <div v-if="selectedAdz" class="header-title">
        <h1 class="big-title" @click.prevent="enterLiveEditMode" @keyup="saveLiveEdit" @blur="endLiveEdit">
          {{ selectedAdz.name }}
        </h1>
        <h2 class="small-title">Create your Ad</h2>
      </div>
    </header>

    <div v-if="adz" class="content-wrap">
      <div class="left-wrap">
        <form v-if="!enterDragMode" action="#" class="create-fb-ads-form" @submit="saveAdzContent">
          <div class="tabs-wrap">
            <div v-if="adzSlides" class="tabs">
              <a href="#" class="activate-drag-mode" @click.prevent="startDragMode">Reorder tabs</a>
              <div v-if="isSaving" class="click-preventer"></div>
              <a v-for="(slide, index) in adzSlides" :key="index + 1" :class="[activetab === index + 1 ? 'active' : '']" @click="activetab = index + 1">{{
                index + 1
              }}</a>
              <a v-if="adzSlides.length < 10" href="#" @click.prevent="addSlide()">+</a>
              <a v-else>&nbsp;</a>
            </div>

            <div v-if="adzSlides" class="tabcontent-wrap">
              <div v-for="(slide, index) in adzSlides" :key="'sv-' + (index + 1)">
                <div v-if="activetab === index + 1" class="tabcontent">
                  <div v-if="adzSlides.length > 2" class="remove-wrap" @click.prevent="removeSlide(index)">
                    <p>Remove</p>
                    <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.844 5.238a.599.599 0 0 0-.607.612v5.522c0 .346.263.612.607.612a.655.655 0 0 0 .606-.612V5.85a.656.656 0 0 0-.606-.612zM9.159 5.238a.655.655 0 0 0-.607.612v5.522c.02.326.283.591.607.612a.599.599 0 0 0 .606-.612V5.85a.599.599 0 0 0-.606-.612z"
                      />
                      <path
                        d="M14.395 2.222h-3.497V1.63C10.898.734 10.17 0 9.28 0H5.722c-.889 0-1.617.734-1.617 1.631v.592L.608 2.222A.599.599 0 0 0 0 2.833c-.02.327.242.592.566.612h1.111l.647 10.026c.04.856.748 1.549 1.617 1.529h7.116c.869.02 1.577-.673 1.617-1.529l.647-10.026h1.071c.324.02.587-.244.607-.57v-.04a.597.597 0 0 0-.605-.613zm-9.077-.59c0-.225.182-.408.404-.408H9.28c.223 0 .404.183.404.407v.592l-4.366-.001v-.59zm6.146 11.757a.385.385 0 0 1-.384.387h-.02l-7.117.001c-.202.02-.384-.143-.404-.366v-.02l-.627-9.946h9.178l-.627 9.944z"
                      />
                    </svg>
                  </div>

                  <input
                    id="file"
                    type="file"
                    name="file"
                    class="add-file"
                    accept=".png, .mp4, .jpeg, .jpg"
                    placeholder="Image ou vidéo"
                    @change="uploadFile($event)"
                  />
                  <p class="sub-label" v-if="slide.tempOriginalFileImg">
                    <a href="#" class="link-p" @click.prevent="resetCrop(slide.tempOriginalFileImg)">
                      <br />
                      Crop again
                    </a>
                  </p>
                  <p class="label">
                    For questions and more information, see the
                    <a href="https://www.facebook.com/business/ads-guide/" target="_blank" class="link-p">Facebook Ad Guidelines.</a>
                  </p>

                  <div class="field-wrap">
                    <div class="input-label-wrap" :class="{ filled: slide.title }">
                      <input v-model="slide.title" type="text" class="label-focus" />
                      <p class="label">Headline (optional)</p>
                    </div>
                    <p
                      class="counter"
                      :class="{
                        'text-danger': (slide.title && maxCountTitre - slide.title.length) < 0,
                      }"
                    >
                      {{ slide.title ? maxCountTitre - slide.title.length : maxCountTitre }}
                    </p>
                  </div>
                  <p class="label">Write a short headline</p>

                  <div class="input-label-wrap" :class="{ filled: slide.url }">
                    <input v-model="slide.url" type="text" class="label-focus" />
                    <p class="label">Website URL</p>
                  </div>
                  <p class="label">Enter the URL for the web page you want people to visit.</p>

                  <div class="input-label-wrap" :class="{ filled: slide.description }">
                    <input v-model="slide.description" type="text" class="label-focus" />
                    <p class="label">Description</p>
                  </div>
                  <p class="label">Include additional details</p>
                </div>
              </div>
            </div>
          </div>

          <div class="field-wrap">
            <div class="input-label-wrap" :class="{ filled: adz.content.text }">
              <textarea v-model="adz.content.text" class="label-focus textarea-focus"></textarea>
              <p class="label">Primary Text</p>
            </div>
            <p
              class="counter"
              :class="{
                'text-danger': (adz.content.text && maxCountText - adz.content.text.length) < 0,
              }"
            >
              {{ adz.content.text ? maxCountText - adz.content.text.length : maxCountText }}
            </p>
          </div>
          <p class="label">Tell people what your ad is about</p>

          <div class="field-wrap">
            <div class="input-label-wrap flex" :class="{ filled: adz.content.cta }">
              <div class="input-text-smaller">
                <select v-model="adz.content.cta" class="label-focus" name="cta" @change="setCtaLabel($event)">
                  <option value=""></option>
                  <optgroup v-for="group in ctaOptions" :key="group.label" :label="group.label">
                    <option v-for="cta in group.ctas" :key="cta" :value="cta">{{ translateCtaLabel(cta) }}</option>
                  </optgroup>
                </select>
                <p class="label">Call to Action</p>
              </div>
              <div class="checkbox-wrap" :class="{ active: ctaLang === 'fr' }" @click.prevent="setCtaLabelLang()">
                <p>French</p>
                <div class="checkbox"><img src="@/assets/img/check-icon-grey.svg" alt="check" /></div>
              </div>
            </div>
          </div>
          <p class="sub-label">Show a button or link on your ad that represents the action you want people to take. <br/> *Some actions are unique to the Campaign objective.</p>

          <div class="multiple-action-button">
            <div class="main-button">
              <button type="submit" to="/Campaign" class="default-action save" @mouseover="setAction('exit')">
                <p>Save</p>
              </button>
              <div class="more" @click="showMoreOptions">
                <img src="../../assets/img/arrow-down.svg" alt="Arrow down" />
              </div>
            </div>
            <div class="options" :class="{ show: moreOptions }">
              <button type="submit" to="/Facebook" @mouseover="setAction('show')"><p>Save and show</p></button>
              <button type="submit" to="/Facebook" @mouseover="setAction('duplicate')">
                <p>Save and duplicate</p>
              </button>
            </div>
          </div>
        </form>

        <div v-else-if="enterDragMode" class="reorder-drag-zone">
          <a href="#" class="done-reordering" @click.prevent="endDragMode">Done reordering</a>
          <draggable :list="adzSlides" :disabled="!enabled" class="list-group" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
            <div class="list-group-item" v-for="(element, index) in adzSlides" :key="index + 1">
              <img class="item-thumb" v-if="element.image" :src="element.image" alt="Preview image" />
              <div v-else class="item-thumb-ph"></div>
              <span>Slide #{{ index + 1 }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#4628ff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="8" y="8" width="12" height="12" rx="2" />
                <line x1="4" y1="4" x2="4" y2="4.01" />
                <line x1="8" y1="4" x2="8" y2="4.01" />
                <line x1="12" y1="4" x2="12" y2="4.01" />
                <line x1="16" y1="4" x2="16" y2="4.01" />
                <line x1="4" y1="8" x2="4" y2="8.01" />
                <line x1="4" y1="12" x2="4" y2="12.01" />
                <line x1="4" y1="16" x2="4" y2="16.01" />
              </svg>
            </div>
          </draggable>
        </div>
      </div>
      <!--LEFT WRAP-->

      <div class="right-wrap">
        <!-- CARROUSEL -->
        <div class="facebook-post">
          <div v-if="isSaving" class="click-preventer"></div>

          <header class="facebook-post-header">
            <div v-if="selectedClient" class="facebook-client">
              <client-logo v-if="selectedClient" :client="selectedClient" class-name="is-small"></client-logo>
              <div class="facebook-client-name">
                <p class="name">{{ selectedClient.name }}</p>
                <div class="sponsor">
                  <p>Sponsorisé</p>
                  <img src="../../assets/img/facebook/world.png" alt="Adz" />
                </div>
              </div>
            </div>
            <p v-if="adz.content.text == ''" class="post-description">Texte</p>
            <p v-else-if="adz.content.text && commentsToggle" class="post-description">{{ adz.content.text.trim() }}</p>
            <p v-else-if="adz.content.text && !commentsToggle" class="post-description">
              <truncate clamp="See More" :length="126" less="Show Less" type="html" :text="adz.content.text.trim()"></truncate>
            </p>
          </header>

          <div class="carrousel-fb">
            <flickity v-if="adzSlides[0]" ref="flickity" class="main-carousel" :options="flickityOptions">
              <div v-for="n in 10" v-show="adzSlides[n - 1]" :key="n" class="carousel-cell">
                <div class="loader-wrap">
                  <div v-if="isSaving && activetab === n" class="loader">
                    <div v-if="isSaving && adzSlides[n - 1] && !adzSlides[n - 1].videoMp4" class="progress">
                      <p class="pourcentage">{{ parseInt(imageProgress) }}<sup>%</sup></p>
                      <div class="progress-bar-wrap">
                        <div class="progress-bar" :style="'width:' + imageProgress + '%'"></div>
                      </div>
                    </div>
                  </div>

                  <img v-if="adzSlides[n - 1] && adzSlides[n - 1].imageRef" :src="fetchThumb(adzSlides[n - 1].imageRef, adzSlides[n - 1].imageRefFromId)" alt="Facebook" />
                  <video v-else-if="adzSlides[n - 1] && adzSlides[n - 1].videoRef" controls autoplay loop muted poster="/">
                    <source :src="fetchVideoThumb(adzSlides[n - 1].videoRef, adzSlides[n - 1].videoRefFromId)" type="video/mp4" />
                  </video>
                  <img v-else src="../../assets/img/simple-product-placeholder.svg" alt="Facebook" />
                </div>

                <footer>
                  <div class="titles">
                    <p class="title">
                      {{ adzSlides[n - 1] && adzSlides[n - 1].title ? adzSlides[n - 1].title : 'Title' }}
                    </p>
                    <p class="subtitle">
                      {{ adzSlides[n - 1] && adzSlides[n - 1].description ? adzSlides[n - 1].description : '' }}
                    </p>
                  </div>
                  <div class="bt-carrousel">
                    <p v-if="adz.content.ctaSelectedLabel">{{ adz.content.ctaSelectedLabel }}</p>
                    <p v-else-if="ctaOptions && ctaOptions.find((i) => i.id === adz.content.cta)">
                      {{ ctaOptions.find((i) => i.id === adz.content.cta).label_en }}
                    </p>
                    <p v-else-if="adz.content.cta == ''">Call to action</p>
                    <p v-else>{{ adz.content.cta }}</p>
                  </div>
                </footer>
              </div>
            </flickity>
          </div>

          <img src="../../assets/img/facebook/post-facebook-footer.jpg" class="post-footer" alt="Facebook" />
        </div>
      </div>
      <!--RIGHT WRAP-->
    </div>

    <!-- Cropper Popup -->
    <div :class="['croppper-popup-wrapper', { 'is-active': imgSrc && !shouldClosePopup }]">
      <div class="cropper-popup">
        <vue-cropper
          v-if="imgSrc"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :drag-mode="'move'"
          :movable="true"
          :rotatable="false"
          :crop-box-movable="true"
          :crop-box-resizable="false"
          :responsive="true"
          :src="imgSrc"
          alt="Source Image"
          preview=".preview"
        >
        </vue-cropper>

        <div v-if="imgSrc" class="actions">
          <div class="zoom-wrap">
            <a href="#" @click.prevent="zoomIn">+<small>1</small></a>
            <a href="#" @click.prevent="zoomInTwice">+<small>2</small></a>

            <a href="#" @click.prevent="zoomOut">-<small>1</small></a>
            <a href="#" @click.prevent="zoomOutTwice">-<small>2</small></a>
          </div>
          <a href="#" role="button" class="bt" @click.prevent="cropImage">Crop</a>
        </div>

        <a href="#" class="close-action">
          <img src="@/assets/img/close.svg" alt="X" @click.prevent="closePopup" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
// Cropper
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import truncate from 'vue-truncate-collapsed'

import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import Flickity from 'vue-flickity'
import draggable from 'vuedraggable'
import { storageRef, firebase } from '@/firebase/init'
import { resetCommentsOnAdz, applyDuplicateIdRefs, slugify, doAlert } from '@/misc/helpers'

import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import FbAdzCallToActionsDB from '@/firebase/fbAdzCallToActions-db'

import CleanupJobsDB from '@/firebase/cleanup/cleanup-jobs-db'
// import PendingVideosConvertionsDB from '@/firebase/pending-videos-convertions-db'

import ClientLogo from '@/components/clients/ClientLogo'
import { translatedCta } from '@/misc/fbAdzCtasTranslation'

export default {
  head: function () {
    return {
      title: {
        inner: 'Créez une campagne',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Flickity,
    ClientLogo,
    VueCropper,
    truncate,
    draggable,
  },

  data: function () {
    return {
      maxCountText: 125,
      remainingCountText: 125,
      maxCountUrl: 30,
      remainingCountUrl: 30,
      maxCountTitre: 40,
      remainingCountTitre: 40,
      message: '',
      UrlLink: '',
      UrlLinkShow: '',
      Titre: '',
      Description: '',
      CallToAction: '',
      hasError: false,

      // Vinc :: Refacto
      imageProgress: 0,
      mode: 'update',
      action: 'exit',
      adz: null,
      adzSlides: [{}, {}],
      maxSlides: 10,
      isSaving: false,

      moreOptions: false,
      ctaOptions: null,
      ctaLang: 'en',

      activetab: 1,
      flickityOptions: {
        imagesLoaded: true,
        cellAlign: 'center',
        groupCells: true,
        pageDots: false,
        percentPosition: false,
        contain: true,
        lazyLoad: 2,
        on: {
          change: (index) => {
            this.activetab = index + 1
          },
        },
      },

      imgSrc: '',
      cropImg: '',
      data: null,
      shouldClosePopup: false,
      hasCropped: false,
      error: '',

      enterDragMode: false,
      enabled: true,
      dragging: false,
    }
  },

  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    campaignId() {
      return this.$route.params.campaignId
    },
    adzId() {
      return this.$route.params.adzId
    },
    ...mapState('app', ['appTitle', 'commentsToggle']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign', 'selectedAdz']),
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          // console.log('account', 'changed')

          this.selectClient(this.$route.params.clientId)
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })
          this.selectAdz({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
            adzId: this.$route.params.adzId,
          })

          if (this.clientId && this.campaignId && !this.adzId) {
            this.mode = 'create'
          } else if (this.clientId && this.campaignId && this.adzId) {
            this.mode = 'update'

            const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.clientId, this.campaignId)
            this.adz = await accountClientCampaignAdzDbRef.read(this.adzId)

            if (this.adz.contentSlider) {
              this.adzSlides = this.adz.contentSlider
            }

            this.countdownText()
            this.countdownUrl()
            this.countdownTitre()

            this.getCtaOptions()
          }
        }
      },
      immediate: true,
    },
    activetab: {
      handler(activetab) {
        if (activetab) {
          // console.log({ activetab })
          const { flickity } = this.$refs
          flickity.select(activetab - 1)
        }
      },
    },
  },

  methods: {
    translateCtaLabel(label) {
      return translatedCta(label, this.ctaLang)
    },

    setAction(action) {
      this.action = action
    },

    removeSlide(index) {
      this.adzSlides.splice(index, 1)
      this.activetab = index
    },

    async saveLiveEdit(e) {
      clearTimeout(this.liveEditBuffer)
      this.liveEditBuffer = null

      this.liveEditBuffer = setTimeout(async () => {
        const target = e.target

        this.liveEditTarget = target

        if (target.getAttribute('contenteditable')) {
          const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.clientId, this.campaignId)

          const updateAdz = { ...this.adz, name: target.textContent.trim() }

          await accountClientCampaignAdzDbRef.update(updateAdz)
          this.adz = updateAdz
        }
      }, 635)
    },
    endLiveEdit() {
      this.liveEditTarget.removeAttribute('contenteditable')

      // Refresh State
      this.selectAdz({
        clientId: this.$route.params.clientId,
        campaignId: this.$route.params.campaignId,
        adzId: this.$route.params.adzId,
      })
    },

    async uploadFile(event) {
      const uploadedFile = event.target.files[0]
      const acceptedFormats = ['image/jpeg', 'image/png', 'video/mp4']

      let isVideo = false
      let fileName = this.adz.name ? this.adz.name.trim() : uploadedFile.name

      // Slugify the fileName
      fileName = await slugify(fileName)

      // console.log({ fileType: uploadedFile.type, fileSize: uploadedFile.size })

      // Validate Format
      if (!acceptedFormats.includes(uploadedFile.type)) {
        doAlert({
          title: 'This file format is not supported',
          type: 'error',
        })
        return
      }

      isVideo = uploadedFile.type.includes('video')

      if (isVideo) {
        this.uploadVideo(uploadedFile, fileName)
      } else {
        // Validate Size
        if (uploadedFile.size / 1000 > 10000) {
          doAlert({
            title: 'The file size must be under 10MB',
            type: 'error',
          })
          return
        }

        const _URL = window.URL || window.webkitURL
        const img = new Image()
        const vueInstance = this
        img.onload = function () {
          if (this.width < 600) {
            doAlert({
              title: 'Image width/height must be at least 600px',
              type: 'error',
            })
            return
          }
          // console.log('Image Ratio detection')
          // console.log(this.width + ' ' + this.height)
          // console.log(vueInstance.aspectRation(this.width, this.height))
          // const checkRatio = vueInstance.aspectRation(this.width, this.height)
          const checkRatio = true
          // if (['1:1'].includes(checkRatio)) {
          if (checkRatio) {
            vueInstance.adz.format = ''
            vueInstance.uploadImage(uploadedFile, fileName, true)
          } else {
            doAlert({
              title: 'Invalid ratio for this image. Please update a valid ratio.',
              type: 'error',
            })
            return
          }
        }
        img.src = _URL.createObjectURL(event.target.files[0])
      }

      return
    },
    async uploadImage(uploadedFile, uploadedFileName, triggerCropper = true) {
      // Create a unique name/sku for the image
      const fileName = `${this.adz.id}-${this.activetab}-${new Date().getTime()}.jpg`
      // console.log(`🖼 name === ${fileName}`)

      const uploadTask = storageRef.child(`${this.account[0].id}/adz/${this.adz.id}/${fileName}`).put(uploadedFile)

      this.isSaving = true

      // We stop the uploading,
      // We let the user choose the image focus zone
      if (triggerCropper) {
        // Show the preview to the user
        const reader = new FileReader()
        reader.readAsDataURL(uploadedFile)
        reader.onload = () => {
          this.adzSlides[this.activetab - 1].tempOriginalFileImg = reader.result
          this.imgSrc = reader.result
          this.shouldClosePopup = false
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(reader.result)
          this.isSaving = false
        }
        return
      }

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.imageProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            // console.log('File available at', downloadURL)

            this.adzSlides[this.activetab - 1]['videoMp4'] = ''
            this.adzSlides[this.activetab - 1]['videoRef'] = ''
            this.adzSlides[this.activetab - 1]['videoWebm'] = ''
            this.adzSlides[this.activetab - 1]['webmVersion'] = ''

            // Image
            this.adzSlides[this.activetab - 1]['image'] = `${downloadURL}&t=${new Date().getTime()}`
            this.adzSlides[this.activetab - 1]['imageRef'] = fileName
            this.adzSlides[this.activetab - 1]['imageRefFromId'] = ''
            this.adzSlides[this.activetab - 1]['imageRefVersion'] = '2.0.0'
            this.adzSlides[this.activetab - 1]['imageFormats'] = '300x300,600x600,1200x1200,1200x628'
            this.adzSlides[this.activetab - 1]['imageRatio'] = '1:1'
            this.adzSlides[this.activetab - 1]['hasThumbs'] = true
            this.adzSlides[this.activetab - 1]['ogImageName'] = uploadedFileName

            this.adzSlides[this.activetab - 1]['hasCropped'] = true
            this.hasCropped = true
            document.querySelector('.add-file').value = ''

            const cleanupJobsDB = new CleanupJobsDB()
            await cleanupJobsDB.create({
              type: 'adz',
              accountId: this.account[0].id,
              clientId: this.clientId,
              campaignId: this.campaignId,
              imageRef: fileName,
            })

            setTimeout(() => {
              this.adzSlides[this.activetab - 1]['image'] = `${downloadURL}&t=${new Date().getTime()}`
              setTimeout(() => {
                this.adzSlides[this.activetab - 1]['image'] = `${downloadURL}&t=${new Date().getTime()}`
                this.isSaving = false
              }, 1555)
            }, 1000)
          })
        }
      )
    },
    async uploadVideo(uploadedFile, uploadedFileName) {
      // Create a unique name/sku for the image
      const fileName = `${this.adz.id}-${this.activetab}-${new Date().getTime()}.mp4`
      // console.log(`📹 name === ${fileName}`)

      const uploadTask = storageRef.child(`${this.account[0].id}/adz/${this.adz.id}/${fileName}`).put(uploadedFile)

      this.isSaving = true
      this.adzSlides[this.activetab - 1]['videoMp4'] = ''
      this.adzSlides[this.activetab - 1]['videoRef'] = ''

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.imageProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            // console.log('File available at', downloadURL)
            this.adzSlides[this.activetab - 1]['image'] = ''
            this.adzSlides[this.activetab - 1]['imageRef'] = ''
            this.adzSlides[this.activetab - 1]['imageRatio'] = ''
            this.adzSlides[this.activetab - 1]['imageFormats'] = ''
            this.adzSlides[this.activetab - 1]['hasThumbs'] = false
            this.adzSlides[this.activetab - 1]['videoMp4'] = downloadURL
            this.adzSlides[this.activetab - 1]['videoRef'] = fileName
            this.adzSlides[this.activetab - 1]['videoRefFromId'] = ''
            this.adzSlides[this.activetab - 1]['videoWebm'] = ''
            this.adzSlides[this.activetab - 1]['webmVersion'] = ''
            this.adzSlides[this.activetab - 1]['ogImageName'] = uploadedFileName

            const cleanupJobsDB = new CleanupJobsDB()
            await cleanupJobsDB.create({
              type: 'adz',
              accountId: this.account[0].id,
              clientId: this.clientId,
              campaignId: this.campaignId,
              imageRef: fileName,
            })

            // this.adz = { ...this.adz, reRender: true }

            // Use cloud functions to fetch Webm version and save it to Adz
            // Video Preview will be generated later...
            // this.adzSlides[this.activetab - 1]['videoWebm'] = url
            // this.adzSlides[this.activetab - 1]['webmVersion'] = '1.0.0'

            // We create a db ref in pendingVideosConvertions DB
            // const pendingVideosConvertionsDbRef = new PendingVideosConvertionsDB()

            // await pendingVideosConvertionsDbRef.create({
            //   accountId: this.account[0].id,
            //   clientId: this.clientId,
            //   campaignId: this.campaignId,
            //   fileName,
            //   concact: `${this.account[0].id}-${this.clientId}-${this.campaignId}-${fileName}`,
            //   isConverted: false,
            //   type: 'multiple'
            // })

            this.isSaving = false
            delete this.adz.reRender
            this.adz = { ...this.adz, reRendered: true }
          })
        }
      )
    },

    closePopup() {
      if (this.shouldClosePopup) {
        this.shouldClosePopup = false
      } else {
        this.shouldClosePopup = true
        document.querySelector('.add-file').value = ''
        this.isSaving = false
      }
    },
    zoomIn() {
      this.$refs.cropper.relativeZoom(0.005)
    },
    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.005)
    },
    zoomInTwice() {
      this.$refs.cropper.relativeZoom(0.25)
    },
    zoomOutTwice() {
      this.$refs.cropper.relativeZoom(-0.25)
    },
    async cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 1200,
          maxHeight: 1200,
        })
        .toBlob(async (blob) => {
          // console.log({ blob })
          let fileName = this.adz.name ? this.adz.name.trim() : 'blob'
          // Slugify the fileName
          fileName = await slugify(fileName)
          this.uploadImage(blob, fileName, false)
        })
      this.shouldClosePopup = true
    },

    gcd(a, b) {
      return b == 0 ? a : this.gcd(b, a % b)
    },

    aspectRation(width, height) {
      const r = this.gcd(width, height)
      return `${width / r}:${height / r}`
    },

    addSlide() {
      if (this.adzSlides.length >= this.maxSlides) {
        return
      }

      this.adzSlides.push({})

      const { flickity } = this.$refs
      flickity.reloadCells()
      flickity.resize()

      setTimeout(() => {
        flickity.reloadCells()
        flickity.resize()

        this.activetab = this.adzSlides.length
      }, 500)
    },

    async saveAdzContent(e) {
      e.preventDefault()

      // Abort if is currently loading/saving
      if (this.isSaving) {
        return
      }

      if (!this.validateUserInput()) {
        return
      }

      let hasError = false

      this.adzSlides.forEach((i, index) => {
        const slide = i
        slide.tempOriginalFileImg = '' // We do not want to store those temp img DATA in DB
        // console.log({ slide })
        if (!hasError && (!slide.url || (slide.url && slide.url.length < 0))) {
          const { flickity } = this.$refs
          flickity.select(index)

          hasError = true

          return
        }
      })

      this.isSaving = true

      // Is Adz considered as 'Ready' to be selected and Previewed?
      if (this.adz.content.text && this.adz.content.cta) {
        this.adz.isReady = true
      } else {
        this.adz.isReady = false
      }

      this.adz.isArchived = false

      let dbAdz = this.adz

      try {
        const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(
          this.account[0].id,
          this.$route.params.clientId,
          this.$route.params.campaignId
        )
        dbAdz = await accountClientCampaignAdzDbRef.update({
          ...this.adz,
          contentSlider: this.adzSlides,
        })
        this.isSaving = false
      } catch (error) {
        // console.log({ error })
        doAlert({ title: error, type: 'error' })
        this.isSaving = false
        return
      }

      let dbAdzId = dbAdz

      if (this.mode === 'create') {
        dbAdzId = dbAdz.id
      }

      if (this.action === 'duplicate') {
        let newAdz = {
          ...this.adz,
          contentSlider: this.adzSlides,
          name: `${this.adz.name} (copy)`,
        }
        newAdz.name = newAdz.name.trim()
        newAdz = applyDuplicateIdRefs(newAdz)
        delete newAdz.id

        try {
          const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(
            this.account[0].id,
            this.$route.params.clientId,
            this.$route.params.campaignId
          )
          dbAdz = await accountClientCampaignAdzDbRef.create(resetCommentsOnAdz(newAdz), null)
          // console.log('Created', dbAdz)
          this.isSaving = false
        } catch (error) {
          // console.log({ error })
          doAlert({ title: error, type: 'error' })
          this.isSaving = false
          return
        }
      }

      // Show Success Prompt
      doAlert({
        title: `Adz saved successfully`,
        type: 'success',
      })
      this.adz = null

      if (this.action === 'show') {
        this.$router.push({
          name: 'Facebook',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
            adzId: dbAdzId,
          },
        })
      } else if (this.action === 'duplicate') {
        dbAdzId = dbAdz.id
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          },
          query: { select: dbAdzId },
        })
      } else {
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          },
        })
      }
    },

    countdownText: function () {
      this.remainingCountText = this.maxCountText - this.message.length
      this.hasError = this.remainingCountText < 0
    },

    countdownUrl: function () {
      this.remainingCountUrl = this.maxCountUrl - this.UrlLinkShow.length
      this.hasError = this.remainingCountUrl < 0
    },

    countdownTitre: function () {
      this.remainingCountTitre = this.maxCountTitre - this.Titre.length
      this.hasError = this.remainingCountTitre < 0
    },

    showMoreOptions() {
      if (this.moreOptions) {
        this.moreOptions = false
      } else {
        this.moreOptions = true
      }
    },

    async getCtaOptions() {
      this.ctaLang = this.adz.ctaLang ? this.adz.ctaLang : 'en'
      this.adz.ctaLang = this.ctaLang

      const fbAdzCallToActions= new FbAdzCallToActionsDB()

      this.ctaOptions = await fbAdzCallToActions.readAll(null, false, 'label', 'asc')


    },

    setCtaLabel(e) {
      this.adz.content.ctaSelectedLabel = e.target.options[e.target.selectedIndex].textContent
    },

    setCtaLabelLang() {
      this.ctaLang = this.ctaLang === 'en' ? 'fr' : 'en'

      this.adz.ctaLang = this.ctaLang

      const saveVal = document.querySelector('[name="cta"]').value
      document.querySelector('[name="cta"]').value = ''
      setTimeout(() => {
        document.querySelector('[name="cta"]').value = saveVal

        this.adz.content.ctaSelectedLabel = document.querySelector('[name="cta"]').options[document.querySelector('[name="cta"]').selectedIndex].textContent
      }, 155)
    },

    validateUserInput() {
      for (const slide of this.adzSlides) {
        if (slide.url) {
          const isUrlRegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
          if (!isUrlRegExp.test(slide.url)) {
            doAlert({
              title: 'Websitel URL must be a valid url with format: "https://.../"',
              type: 'error',
            })
            return false
          }
        }

        return true
      }
    },

    resetCrop(imgData) {
      if (imgData) {
        this.imgSrc = imgData
        this.shouldClosePopup = false
        // rebuild cropperjs with the updated source
        this.$refs.cropper.replace(imgData)
        this.isSaving = false
      }
    },

    startDragMode() {
      this.enterDragMode = true
    },

    endDragMode() {
      this.enterDragMode = false
    },

    fetchThumb(fullPath, imageFromId = '', ratio = false) {
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/
      // homme-fr-bgap4TchhlUIv4CI57In.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
      const adzId = imageFromId ? imageFromId : this.adz.id
      let thumbPath = fullPath
      if (ratio) {
        if (ratio === '1:1') {
          thumbPath = thumbPath.replace('.jpg', '_1200x1200.jpg') // Thumbs format
          thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${this.account[0].id}%2Fadz%2f${adzId}%2Fthumbs2%2F${thumbPath}?alt=media`
        }
        if (ratio === '1.91:1') {
          thumbPath = thumbPath.replace('.jpg', '_1200x628.jpg') // 2 format
          thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${this.account[0].id}%2Fadz%2f${adzId}%2Fthumbs2%2F${thumbPath}?alt=media`
        }
      } else {
        thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${this.account[0].id}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      }
      return thumbPath
      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/4Wo75vIGxneCEvXnlrA6xH75f8u1%2Fadz%2Fbgap4TchhlUIv4CI57In%2Fthumbs2%2Fhomme-fr-bgap4TchhlUIv4CI57In_600x600.jpg?alt=media&token=d385c574-5401-4573-b56d-99417d9d1054
    },
    fetchVideoThumb(fullPath, imageFromId = '') {
      const adzId = imageFromId ? imageFromId : this.adz.id
      let thumbPath = fullPath
      thumbPath = `https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/${this.account[0].id}%2Fadz%2f${adzId}%2F${thumbPath}?alt=media`
      return thumbPath
    },

    ...mapActions('users', ['selectClient', 'selectCampaign', 'selectAdz']),
    ...mapActions('app', ['enterLiveEditMode']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/page-title.scss';
@import '@/theme/crop-pop.scss';

.content-wrap {
  display: flex;
}

.left-wrap {
  width: 100%;
  margin-right: 20px;
}

.inner-page-header {
  justify-content: flex-start;
}

.create-fb-ads-form {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  text-align: left;

  .add-file {
    background-image: url(../../assets/img/add-file.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    cursor: pointer;
  }

  textarea {
    max-width: 660px;
  }

  .sub-label {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 30px;
    opacity: 0.7;

    a {
      color: #4628ff;
    }
  }

  .label {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
  }

  .multiple-action-button {
    p {
      margin-bottom: 0;
      color: #fff;
      font-weight: 600;
    }

    .main-button {
      display: flex;
      justify-content: center;
    }

    .default-action {
      padding: 15px 25px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      min-width: 200px;
      cursor: pointer;

      font-family: 'Open Sans', sans-serif;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-align: left;

      transition: all 0.4s ease;

      background: linear-gradient(to right, #2e7df2, #8a23f1);
    }

    .more {
      display: flex;
      align-items: center;
      padding: 15px 25px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;
      background-color: #8a23f1;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    .options {
      width: 263px;
      margin: 0 auto;
      border-left: 1px solid #e0e2e6;
      border-right: 1px solid #e0e2e6;
      border-bottom: 1px solid #e0e2e6;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      display: none;
      z-index: -1;

      &.show {
        display: block;
        z-index: 1;
      }

      button {
        width: 100%;
        background-color: #fff;
        padding: 15px 25px;
        font-family: 'Open Sans', sans-serif;
        color: $dark-grey;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        cursor: pointer;
        transition: all 0.4s ease;

        p {
          color: $dark-grey;
        }

        &:hover {
          background-color: #e0e2e6;
        }

        &:last-child {
          border-top: 1px solid #e0e2e6;
        }
      }
    }
  }

  .add-file {
    background-image: url(../../assets/img/add-file.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    cursor: pointer;

    width: 100%;
    height: 60px;
    padding: 20px 15px;
    font-size: 14px;
    color: #637082;
    font-weight: 600;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif !important;
    border-radius: 3px;
    margin-bottom: 5px;
    border: 1px solid #e0e2e6;
  }

  select {
    padding-left: 20px;
  }

  .field-wrap {
    position: relative;
    .counter {
      position: absolute;
      right: 20px;
      bottom: 20px;
      margin-bottom: 0;
      font-size: 14px;
      color: #5cc;
      font-weight: 600;

      &.text-danger {
        color: #f12f80;
      }
    }
  }

  p {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 35px;
  }

  .bt-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    &.is-loner {
      padding: 10px 0;

      .bt {
        margin: 0 auto;
      }
    }
  }

  .bt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px);
    padding: 15px 20px 15px 20px;

    &.save {
      background: $dark-grey;
    }
    p {
      position: relative;
      left: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      transition: all 0.2s ease;
    }

    &:hover {
      p {
        left: -15px;
      }
    }
  }
}

.facebook-post {
  position: relative;
  width: 500px;
  background-color: #fff;
  border: 1px solid #dadde1;
  border-radius: 3px;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #1d2129;
  overflow: hidden;
}

.facebook-post-header {
  padding: 12px;
}

.facebook-client {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    flex-basis: 40px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.facebook-client-name {
  width: calc(100% - 40px);
  position: relative;
  top: 6px;
  left: 10px;

  .name {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    color: #385898;
  }

  .sponsor {
    display: flex;

    img {
      flex-basis: 12px;
      width: 12px;
      height: 12px;
    }

    p {
      color: #a4a4a4;
      font-size: 12px;
      margin-right: 5px;
    }
  }
}

.post-description {
  color: #1d2129;
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;
  white-space: pre-wrap;
}

video {
  width: 100%;
  display: block;
}

.carrousel-fb {
  :hover {
    .carousel-cell {
      opacity: 0.3;

      &.is-selected {
        opacity: 1;
      }
    }
  }

  .carousel-cell {
    width: 308px;
    padding-left: 6px;
    transition: all 0.2s ease;

    img {
      width: 302px;
      height: 302px;
      border-top: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    footer {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      padding: 12px;
      background-color: #fff;
      border: 1px solid #e5e5e5;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-bottom: 2px;

      .titles {
        min-width: 0;
      }

      .title {
        width: 100%;
        font-size: 14px;
        color: #1c2028;
        font-weight: 500;
        padding-right: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .subtitle {
        width: 100%;
        font-size: 11px;
        color: #65676b;
        font-weight: 400;
        padding-right: 10px;
        padding-top: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .bt-carrousel {
        width: auto;
        background-color: #e4e6eb;
        padding: 8px 12px;
        border-radius: 2px;
        text-decoration: none;

        p {
          color: #050505;
          font-weight: 500;
          font-size: 15px;
          white-space: nowrap;
        }
      }
    }
  }

  .loader-wrap {
    position: relative;
    .loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(31, 37, 50, 0.8);
    }

    .progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-width: 350px;
      text-align: center;

      .pourcentage {
        font-family: 'Open Sans', sans-serif;
        font-size: 40px;
        color: #fff;
        font-weight: 300;
        sup {
          font-size: 50%;
          font-weight: 400;
        }
      }

      .progress-bar-wrap {
        width: 100%;
        height: 8px;
        background: #fff;
        border-radius: 20px;

        .progress-bar {
          width: 0%;
          height: 8px;
          background: linear-gradient(to right, #2e7df2, #8a23f1);
          transition: all 0.4s ease;
        }
      }
    }

    .video-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;

      .message {
        font-family: 'Open Sans', sans-serif;
        font-size: 22px;
        color: #fff;
        font-weight: 300;
      }
    }
  }

  .flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-prev-next-button {
    top: 43%;
    width: 35px;
    height: 45px;
    background-color: #fff;
    border-radius: 0;
    border-top: 2px solid #f4f4f4;
    border-left: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
  }

  .flickity-button-icon {
    fill: #f00 !important;
  }

  .flickity-prev-next-button.next {
    right: 0;
  }

  .flickity-prev-next-button.previous {
    left: 0;
  }

  @media screen and (max-width: 1024px) {
    .flickity-button {
      display: none;
    }
  }
}

.post-footer {
  width: 498px !important;
}

.tabs-wrap {
  width: 100%;
  margin-bottom: 35px;

  .tabs {
    position: relative;
    display: flex;
    a {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $grey;
      color: $dark-grey;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;

      &.active {
        background-color: $dark-grey;
        color: #fff;
      }

      &:last-child {
        background-color: transparent;
        color: $dark-grey;
        font-weight: 600;
        font-size: 22px;
      }
    }

    a.activate-drag-mode {
      position: absolute;
      top: 50%;
      right: 0;

      font-size: 13px;
      padding: 0 0;
      display: inline-block;
      margin: 0;
      color: $purple-blue;
      background: transparent;
      width: auto;
      line-height: 1;
      font-weight: 600;
    }
  }

  .tabcontent-wrap {
    position: relative;
    padding: 45px 20px 20px 20px;
    background-color: $grey;

    .tabcontent {
      .label {
        margin-bottom: 15px;
      }
    }
  }

  .remove-wrap {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-bottom: 0;
      margin-right: 5px;
      transition: all 0.1s ease-in-out;
    }

    svg {
      fill: #637082;
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      p {
        color: #f12f80;
      }
      svg {
        fill: #f12f80;
      }
    }
  }
}

// textarea {
//   width: 100%;
//   height: 100px;
// }
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  display: none;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
.click-preventer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.12);
}

.done-reordering {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 13px;
  padding: 0 0;
  display: inline-block;
  margin: 0;
  color: #4628ff;
  background: transparent;
  width: auto;
  line-height: 1;
  font-weight: 600;
}

.reorder-drag-zone {
  position: relative;
  padding-top: 15px;

  .list-group {
    .list-group-item {
      display: flex;
      align-items: center;

      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      font-size: 14px;
      color: #637082;
      font-weight: 600;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid #e0e2e6;
      line-height: 1;
      margin: 10px 0;

      span {
        padding: 20px 15px;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }

      .item-thumb {
        display: block;
        height: auto;
        width: 53px;
        background-repeat: 1px solid rgba(#000, 0.25);
      }

      .item-thumb-ph {
        display: inline-block;
        height: 53px;
        width: 53px;
        background: rgba(#000, 0.025);
        background-repeat: 1px solid rgba(#000, 0.25);
      }
    }
  }
}
</style>
